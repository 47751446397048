import React from 'react';

const Footer = () => {
	return (
		<footer className='homepage-footer'>
			<p>Made with &hearts; by zoef shaikh</p>
		</footer>
	);
};

export { Footer };
