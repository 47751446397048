const IMAGES = {
	GOT1: require('./games/GOT-1.png').default,
	GOT2: require('./games/GOT-2.png').default,
	HFW1: require('./games/HFW-1.png').default,
	HFW2: require('./games/HFW-2.png').default,
	LOUS1: require('./games/LOUS2-1.png').default,
	LOUS2: require('./games/LOUS2-2.png').default,
	MilesMorales1: require('./games/MIlesMorales-1.png').default,
	MilesMorales2: require('./games/MIlesMorales-2.png').default,
	Uncharted1: require('./games/Uncharted-1.png').default,
	Uncharted2: require('./games/Uncharted-2.png').default,
	NintendoSwitchMario1: require('./consoles/NintendoSwitchMario-1.jpg').default,
	NintendoSwitchMario2: require('./consoles/NintendoSwitchMario-2.png').default,
	PS4_1: require('./consoles/ps4-1.png').default,
	PS4_2: require('./consoles/ps4-2.png').default,
	PS5_1: require('./consoles/ps5-1.png').default,
	PS5_2: require('./consoles/ps5-2.png').default,
	XBOX1: require('./consoles/xbox-1.jpg').default,
	XBOX2: require('./consoles/xbox-2.png').default,
	NintendoSwitchJoycon1: require('./accessories/NintendoSwitchJoycon-1.jpg').default,
	NintendoSwitchJoycon2: require('./accessories/NintendoSwitchJoycon-2.png').default,
	PS5ControllerB1: require('./accessories/ps5controller-b-1.png').default,
	PS5ControllerB2: require('./accessories/ps5controller-b-2.png').default,
	PS5ControllerR1: require('./accessories/ps5controller-r-1.png').default,
	PS5ControllerR2: require('./accessories/ps5controller-r-2.png').default,
	XboxController1: require('./accessories/xbox-controller-1.jpg').default,
	XboxController2: require('./accessories/xbox-controller-2.png').default,
};

export default IMAGES;
